/*  

*/  
.aboutVisible{
    height: 45rem;
}

.projectVisible{
    height: 45rem;
}
*{
    font-family: 'Lato';
}

@font-face{
    font-family: 'Gasket';
    src: local('Gasket'), url(../../fonts/Gasket.ttf) format('truetype');

    font-family: 'Lato';
    src: local('Lato'), url(../../fonts/Lato/Lato-Regular.ttf) format('truetype');
}

/* Navbar */

#navbar-small{
    flex-wrap: nowrap ;
    position: fixed;
    top: 0;
    height: 7.25rem;
    width: 100%;
    /* background-color: rgb(22, 22, 22); */
    background-color: black;
    text-transform: uppercase;
}

.app-navbar{
    /* height: 8.5rem; */
    /* margin-bottom: 8.5rem; */
    /* overflow: hidden; */
    position: relative;
    z-index: 3;
}

.nav-title{
    color: black;
}

.site-navbar-small{
    position: absolute;
    left: 0px;
    top: 0px;
    background:rgb(22, 22, 22);
    min-height: 758vh;
    width: 19.8rem;
    overflow: hidden;
}

Container{
    height: 100%;
}

.navbar-content{
    margin-top: 4rem;
    text-align: justify;
    padding: 20px;
    font-size: 1rem;
    width: 100%;
}

.navbar-content > a{
    margin-top: 2.5rem;
    /* margin-bottom: 2rem; */
}

.navbar-title{
    color: #90720f;
    font-family: 'Gasket';
    border-radius: 10px;
    padding: 3px;
    width: max-content;
    font-weight: 900;
    font-size: 28px;
}

/* .vl{
    margin-top: 10px;
    margin-bottom: 10px;
    min-height: 25px;
    border-top: 2px solid #90720f;
    width: 260px;
} */

.links, .links2, #basic-nav-dropdown{
    font-family: 'Gasket';
    font-size: 1.3rem;
    /* color: #90720f; */
    /* color: #715807; */
    color: goldenrod;
    background-color: transparent;
}

.links:hover, .links2:hover, #basic-nav-dropdown:hover{
    color: white;
}

.navbar-big{
    /* position: fixed; */
    background-color: rgb(22, 22, 22);
    text-transform: uppercase;
    top: 0;
    /* animation: 7s ease-in 5s 1 slideInFromLeft; */
    /* transition: all ease-in-out 1s !important; */
    height: 8.5rem;
    color: white;
    width: 100%;
    z-index: 100;
}

.navbar-big.navbar-light {
    background-color: rgb(22, 22, 22);
    color: white;
}

.logoheader{
    /* margin-top: 0.3rem; */
    height: 8rem !important;
}

.dropdown-menu{
    background-color: rgb(22, 22, 22);
    border-top: 1px solid #90720f;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}
.dropdown-item{
    font-family: 'Times New Roman', Times, serif;
    font-size: 1.15rem;
    color: #90720f;
}

.links2{
    margin: 2rem;   
}

a.links2.nav-link, a#basic-nav-dropdown.dropdown-toggle.nav-link  {
    /* color: #715807; */
    color: goldenrod;
    /* background-color: transparent; */
}

/* SlideImg-Home */

.carousel-caption{
    top:40%;
}

.carousel-item-img{
    /* opacity: 0.85; */
    height:82vh !important;
    display: block;
    width: 100%;
    object-fit: cover;
}

.carousel-slider-text{
    font-size: 3rem;
    font-weight: 500;
    color: black;
    font-family: 'Gasket';
}   

.carousel-slider-caption{
    border-radius: 20px;
    /* height: 50%; */
    width: 100%;
    background-color: rgba(255, 255, 255, 0.475);
}

/* About */

.about-title{
    padding: 1vh 2vh 1vh 0vh;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    text-align: center;
    font-size: 2.5rem;
    font-weight: 600;
    /* color: #715807; */
    color: rgb(179, 136, 28);
    font-family: 'Gasket';
    text-transform: uppercase;
}


.card{
    margin: 1rem 1rem;
    height: 8rem;
    width: 20rem;
    background-color: rgba(156, 122, 11, 0.592);
}

.card-content{
    position: relative;
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    font-family: 'Lato';
    font-weight: 500;
    font-size: 1.7rem;
}

.card-text-content{
    color: #513f02;
    /* color:  rgba(156, 122, 11, 0.592); */
    font-size: 1.5rem;
    /* font-size: 1.37rem; */
    padding: 0.5rem;
    text-align: center;
}

.card-year-content{
    font-size: 1.9rem;
    font-weight: 600;
    color: #513f02;
}

.card-text-content:hover{
    /* font-size: 1.4rem; */
    color: #513f02;
    transition: 0.5s ease-in;
}

.about-youtube{
    display: flex;
    justify-content:space-between;
    overflow: hidden;
    position: relative;
    height: 100%;
    text-align: start;
}

.about-video{
    margin-top: 5%;
    width: 100%;
    justify-content: center;
    display: flex;
}

.about-timestamp{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
}

.about-text, .about-text-content{
    height: 10rem;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 1.3rem;
    font-weight: 300;
    font-family: 'Lato';
    text-transform: uppercase;
    text-align: justify;
    hyphens: auto;
    -webkit-hyphens: auto;
}

/* .about-text-content{
    .fade-in{
        opacity: 0;
        animation: fadeIn 1s ease-in-out forwards;
    }

    @keyframes fadeIn {
        0% {
            opacity: 0;
        }

        100%{
            opacity: 1;
        }
    }
} */

.founder{
    margin-top: 1rem;
    width:18rem;
    /* height:75%; */
    height: 25rem;
    object-fit: cover;
}


.founder-info{
    margin-top: 1rem;
    font-size: 1.5rem; 
    font-family: 'Lato';
}

/* CarouselProjects */

.carouselContainer{
    height: 100%;
    position: relative;
    overflow: hidden;
    /* border-top-left-radius: 5px;
    border-top-right-radius: 5px; */
    /* box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 15px; */
}

.carouselSlider{
    height: 100%;
    display: flex;
    transition: transform 1.5s ease-out;
}

.carousel-leftbtn{
    position: absolute;
    top: 50%;
    transform: translate(90%, -50%);
    right: 32px;
    font-size: 30px;
    z-index: 1;
    cursor: pointer;
}

.carousel-rightbtn{
    position: absolute;
    top: 50%;
    transform: translate(-90%, -50%);
    left: 32px;
    font-size: 30px;
    z-index: 1;
    cursor: pointer;
}

.carouselText{
    position: relative;
    font-size: 1.5rem;
    color: #000;
    width: 100%;
    padding: 0.6rem;
    text-align: center;
    /* border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px; */
    background-color: rgba(203, 203, 203, 0.092);
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px;
}

/*Comleted Projects*/

.projectsContainer{
    height: 14rem;
    margin: 0 auto 6rem auto;
}

.completedTitle{
    display: none;
}

.completedProjects{
    margin: 0rem 1rem 0 1rem;
}


/* Contact Us */

.contact-us{
    font-size:2em;
    margin-top: 240px;
}

/* Footer */

.footer-container{
    color: white;
    display: flex;
    height:18rem;
    background-color: black;
    margin-top: 3rem;
}

.footer-img{
    margin: 0.5rem;
    /* width: 70rem; */
    /* align-items: center;
    justify-content: center; */
}
.logo-footer{
    height:100%;
    /* filter: grayscale(1); */
    padding: 0rem 0rem 0.5rem 0.5rem;
    /* border: #fff dashed 3px; */
    width: 14rem;
    object-fit: contain;
}


.footer-content{
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 1.5rem 2rem;
    display: flex;
    align-items: center;
    justify-items: center;
}

.footer-location{
    display: flex;
    /* width: 100%; */
    height:100%;
    align-items: flex-start;
    align-items: center;
    /* border: 2px white solid; */
    flex-direction: row;
}

.footer-social-media{
    display: grid;
    width: fit-content;
    border-top: 2px solid white;
    margin-top:1rem;
    justify-content: center;
    align-self: end;
}
.social-others{
    justify-self: start;
}

.social-others > a{
    width: 55px !important;
    height: 55px !important;
}
.social{
    display: flex;
    grid-column: none;
    justify-self: start;
}
.footer-icons{
    display: flex;
    flex-direction: column-reverse;
    font-size:6.5rem;
    margin: 1rem;
}

.map-footer{
    display: flex;
    align-items: center;
    margin-right: 1rem;
}
.locationIcon{
    height: 3rem;
    color: goldenrod;
}

.locationinfo{
    margin: 1rem 1.5rem;
    align-self: auto;
    font-size: 1.6rem;
    text-align: start;
    color: gold;
}

.mailinfo{
    font-size:1.5rem;
    font-family: 'Lato';
    align-self: center;
}

  

@keyframes slideInFromLeft {
    0% {
        transform: translateY(40%);
    }
    100% {
        transform: translateZ(10%);
    }
}

@keyframes slideRight{
    from{
        margin-left:-100%;
    }
    to{
        margin-left:0%;
    }
}

/* horiizontal mode */
@media (min-width: 992px) {
    /* .container{
        max-width:100%;
    } */

    .logoheader{
        height: 10rem;
        width: auto;
    }

}

@media only screen and (max-width: 994px){
    /* header .nav-btn{
        visibility: hidden;
        opacity: 1;
    } */
    /* header nav{
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        display: flex;
        visibility: visible;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1.5rem;
        background-color: blue;
        transition: 1s;
        transform: translateY(-100vh);
    }

    header .responsiveNav{
        transform: none;
    } */
}

/* Phone */
@media only screen and (max-width: 992px){
    .app-navbar{
        height: 7.25rem;
    }


    .sidebar{
        position: fixed;
        top: 0;
        left: 0;
        /* width: 63%; */
        height: 100%;
        background-color: #000;
        z-index: 2;
        overflow-y: auto;
        box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
    }

    .btn:hover{
        background-color: transparent !important;
        color: goldenrod !important;
    }
    
    .btn:active{
        color: goldenrod !important;
    }

    .btn-outline-dark{
        color: goldenrod;
        /* border: 1px solid goldenrod; */
        border: none;
        width: 33.33%;
        text-align: left;
        font-size: 1.2rem;
        /* margin-left: 1rem; */
        margin-top: 1.2rem;
    }

    .close-button{
        position: absolute;
        top: 10px;
        right: 10px;
        color: goldenrod;
        /* background-color: black; */
        /* outline: goldenrod; */

    }
    

    .aboutVisible{
        height: 100%;
    }

    .projectVisible{
        height: 100%;
    }

    .card-year-content{
        font-size: 1.5rem;
    }

    #basic-nav-dropdown{
        margin-top: 3rem;
    }

    .footer-social-media{
        margin-top: 0.7rem;
        padding-top: 0.1rem;
        display: none;
    }

    .social-media{
        width: 33.33%;
        justify-self: center;
       align-self:end;
        /* margin-top: 0.7rem; */
        /* padding-top: 0.1rem; */
    }

    .social{
        /* justify-self: start; */
        /* flex-direction: row;
        flex-wrap: wrap; */
    }

    .social > a{
        width: 25px !important;
        height: 25px !important;
        margin: 0.2rem 0.2rem;
    }

    .social-icons{
        display: flex;
        padding: 1rem;
        flex-wrap: wrap;
        /* margin: 0.2rem 0.5rem 0 0.5rem ; */
    }

    .container{
        width: 100%;
    }

    .footer-container{
        flex-wrap: wrap;
        height: 10rem;
        width: 100%;
    }

    .footer-img{
        width: 30%;
       
    }

    .logo-footer{
        width: 100%;
        padding: 0 0.2rem
    }

    .map-footer{
        margin-right: 0;
        width: 100%;
    }

    .map-footer > iframe{
        height: 100%;
        margin: 0;
        opacity: 0.8 !important ;
    }

    .footer-content{
        height: 100%;
        width: 65%;
        padding: 2rem 1rem 2rem 0;
    }

    .locationinfo{
        font-size: 0.86rem;
        margin: 0 0rem;
    }

    .locationIcon{
        height: 32px;
    }

    .footer-icons{
        margin: 0.2rem 0.5rem 0 0.5rem ;
    }

    .social-others > a{
        height: 30px !important;
        width: 30px !important;
    }

    .dropdown-menu{
        background-color: rgb(26, 26, 26);
        border-top: 1px solid #90720f;
    }   

    .carousel-item-img{
        height: 25vh !important;
        display: block;
        width: 100%;
    }

    .carousel-slider-text{
        font-size: 1.5rem;
        font-weight: 400;
        /* margin: 0%; */
    }

    .home-projects-text{
        font-size: 2.5rem;
    }

    .home-projects-title{
        font-size:3rem;
    }

    .home-projects-title2{
        font-size:3rem;
        margin-top: -10.9vh;
        margin-left: 2.5%;
    }

    .read-more-btn{
        margin: 20px 0px 0px 1px;
        font-size: 10px;
    }

    .logobrand{
        display: flex;
        margin-right: 0rem;
        align-items: center;
        height: 100px;
        width: 33.33%;
        justify-content: center;
        margin: 2px 0px 4px 0px;
    }

    .logoheader{
        height: 7rem !important;
        width: auto;
        
    }

    .about-header, .about-header2{
        display: block;
        text-transform: uppercase;
        text-align: center;
        font-size: 12.5vw;
        margin-top: 1vh;
        font-weight: 700;
        letter-spacing: 1rem;
        animation: 1s ease-out 0s 1 slideInFromLeft;
    }
    .about-header2{
        opacity: 0.3;
        margin-top: -1.43em;
        margin-left:3%;
    }

    .about-title{
        font-size: 2rem;
        margin-top: 1vh;
        font-weight: 400;
        animation: 2s ease-out 0s 1 slideRight;
        text-align: left;
    }

    .title1{
        height: 7rem;
    }
    .title2{
        /* height: 12rem; */
    }

    .about-text{
        height: 100%;
        font-size: 1rem;
        font-weight: 200;
        text-align: justify;
        justify-content: start;
        /* hyphens: 100 !important; */
        /* text-justify: newspaper; */
        
    }

    .about-youtube{
        display: contents;
    }

    .about-timestamp{
        row-gap: 0.5rem;
        width: 100%;
    }

    iframe{
        margin-top: 2vh;
        width: 100%;
        max-width: 167%;
        height: 32vh;
    }

    .founder{
        height: 11rem;
        width: 9rem;
    }

    .founder-img-container{
        height:100% !important;
    }

    .founder-info{
        margin-top: 1rem;
        font-size: 17px;
    }

    .career{
        font-size: 16px;
        margin-top: 3rem;
        /* margin: 0.1rem; */
    }

    .career-howdidwe-text{
        text-align: justify;
        margin: 0 !important;
        font-weight: 200;
        font-size: 1.3rem;
    }

    .career-resume{
        font-size: 1.5rem;
        font-weight: 550;
        margin-left: 0 !important;
        margin-top: 2rem;
        text-align: left;
        /* animation: 2s ease-out 0s 1 slideRight; */
    }

    .career-email{
        text-align: start;
        font-size: 1.4rem;
        margin-left: 0 !important;
        /* margin: 0.5rem 0rem 0rem 1rem; */
    }


    .completedProjects{
        margin: 0;
    }

    .img1{
        margin-left: 0;
    }

    .img2{
        margin-right: 0;
    }
    
}