.img1{
 margin-left: 3rem;
}

.img2{
 margin-right: 4rem;
}

.founder-img-container{
    height:31rem;
}